.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 12px 24px 16px -15px;
}

.logo img {
  width: 120px;
  height: 30px;
  margin: -5px 12px 22px -12px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.site-page-header {
}

.dropdown-btn > .ant-dropdown-trigger {
  border: none;
}

.dropdown-btn > .ant-dropdown-trigger > span {
  background-color: white !important;
}
