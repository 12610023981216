.login-logo {
  margin-bottom: 50px;
}

.wrap {
  width: 480px;
  height: 460px;
  position: absolute;
  left: 47%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.content {
  background-color: white;
  margin-top: 5%;
  box-shadow: 40px 40px 60px rgb(0 0 0 / 15%);
  padding: 100px 50px;
  width: 480px;
  height: 460px;
}

.login-container {
  width: 360px;
  margin: 0 auto;
}

.login-form-button {
  width: 100%;
  height: 80px;
}

.loginInput {
  border-bottom: 1px solid darkgray;
}

/*
input[type="password"] {

  border-radius: 2px !important;
  -webkit-border-radius: 2px!important;
  border: 1px solid #c2c2c2;
  box-shadow: none;
  font-size: 16px;
  color: #444;
  background: #f9f9f9;
  width: 100%;
  height: 35px!important;
  line-height: 30px !important;
  padding-left: 10px;


}

#userId {
  border-radius: 2px !important;
  -webkit-border-radius: 2px!important;
  border: 1px solid #c2c2c2;
  box-shadow: none;
  font-size: 16px;
  color: #444;
  background: #f9f9f9;
  width: 100%;
  height: 45px!important;
  line-height: 45px !important;
  padding-left: 10px;
}

#userPw {
  border-radius: 2px !important;
  -webkit-border-radius: 2px!important;
  border: 1px solid #c2c2c2;
  box-shadow: none;
  font-size: 16px;
  color: #444;
  background: #f9f9f9;
  width: 100%;
  height: 45px!important;
  line-height: 45px !important;
  padding-left: 10px;
}
*/

#login_footer {
  margin-top: 10px;
}

#BtnLogin {
  border-color: #ffffff;
  height: 45px;
  /* color: #ffffff; */
  color: #000000;
  /* background: #191a45; */
  background: #f6d020;
}
